export default class BusMapIcon {
    /**
     * @param {Object} ti - transport information
     */
    constructor(ti){
        this.ti = ti;
        this.width = 48;
        this.height= 48;
        this.data = new Uint8Array(this.width * this.height * 4);
    }   //constructor
    onAdd(){
        var canvas = document.createElement('canvas');
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext('2d');
    }   //onAdd
    render(){
        const ctx = this.context;
        var radius = (this.width / 2) * 0.75;
        ctx.clearRect(0, 0, this.width, this.height);
        ctx.beginPath();
        ctx.arc(
                    this.width / 2,
                    this.height / 2,
                    radius,
                    0,
                    Math.PI * 2
        );
        ctx.fillStyle = 'rgba(255, 100, 100, 1)';
        ctx.strokeStyle = 'white';
        ctx.lineWidth = 2;
        ctx.fill();
        ctx.stroke();
        this.data = ctx.getImageData(0, 0, this.width, this.height).data;
        return true;
    }   //render
}